import React from "react";
import PropTypes from "prop-types";
import { Icon } from "../../components";

import "./Button.scss";

/*
use this component as follows:
1- by default it's primary (blue color) and 144px wide.
2- you can give it "wide" property and it'll expand it's parent width.
3- you can give it "flat" property and its color will turn white.
4- you can also add tiny or small for the corresponding styles.
*/
const Button = ({
  wide,
  flat,
  flatWhite,
  content,
  onClick,
  id,
  loading,
  iconName,
  small,
  tiny,
  disabled,
  className,
  color,
  secondary,
  chameleon,
  style,
  children,
  type = "button",
}) => {
  return (
    <button
      // TODO: make this id conditional
      id={id}
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={`button button-component ${className} ${
        loading ? "is-loading" : ""
      } ${secondary ? "secondary" : ""} ${chameleon ? "chameleon" : ""} ${
        flat ? "is-white" : ""
      } ${flatWhite ? "is-white-flat" : ""} ${small ? "small" : ""} ${
        tiny ? "tiny" : ""
      }`}
      style={{ width: `${wide ? "100%" : ""}`, color, ...style }}
    >
      {iconName && <Icon name={iconName} />}
      {content ? content : children}
    </button>
  );
};

Button.propTypes = {
  wide: PropTypes.bool,
  flat: PropTypes.bool,
  secondary: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func.isRequired,
  iconName: PropTypes.string,
  small: PropTypes.bool,
  tiny: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  loading: PropTypes.bool,
  color: PropTypes.string,
  chameleon: PropTypes.bool,
  style: PropTypes.object,
};

Button.defaultProps = {
  disabled: false,
  className: "",
  wide: false,
  flat: false,
  secondary: false,
  content: "",
  iconName: "",
  small: false,
  tiny: false,
  id: "",
  loading: false,
  color: "",
  chameleon: false,
  style: {},
};

export default Button;
