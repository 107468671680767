export const FETCH_WORKSPACES = "FETCH_WORKSPACES";
export const FETCH_WORKSPACES_PENDING = "FETCH_WORKSPACES_PENDING";
export const FETCH_WORKSPACES_REJECTED = "FETCH_WORKSPACES_REJECTED";
export const FETCH_WORKSPACES_FULFILLED = "FETCH_WORKSPACES_FULFILLED";

export const FETCH_PROGRAMS = "FETCH_PROGRAMS";
export const FETCH_PROGRAMS_PENDING = "FETCH_PROGRAMS_PENDING";
export const FETCH_PROGRAMS_REJECTED = "FETCH_PROGRAMS_REJECTED";
export const FETCH_PROGRAMS_FULFILLED = "FETCH_PROGRAMS_FULFILLED";

export const SAVE_PROGRAM = "SAVE_PROGRAM";
export const SAVE_PROGRAM_PENDING = "SAVE_PROGRAM_PENDING";
export const SAVE_PROGRAM_REJECTED = "SAVE_PROGRAM_REJECTED";
export const SAVE_PROGRAM_FULFILLED = "SAVE_PROGRAM_FULFILLED";

export const ARCHIVE_PROGRAM = "ARCHIVE_PROGRAM";
export const ARCHIVE_PROGRAM_PENDING = "ARCHIVE_PROGRAM_PENDING";
export const ARCHIVE_PROGRAM_REJECTED = "ARCHIVE_PROGRAM_REJECTED";
export const ARCHIVE_PROGRAM_FULFILLED = "ARCHIVE_PROGRAM_FULFILLED";

export const UNARCHIVE_PROGRAM = "UNARCHIVE_PROGRAM";
export const UNARCHIVE_PROGRAM_PENDING = "UNARCHIVE_PROGRAM_PENDING";
export const UNARCHIVE_PROGRAM_REJECTED = "UNARCHIVE_PROGRAM_REJECTED";
export const UNARCHIVE_PROGRAM_FULFILLED = "UNARCHIVE_PROGRAM_FULFILLED";

export const LEAVE_PROGRAM = "LEAVE_PROGRAM";
export const LEAVE_PROGRAM_PENDING = "LEAVE_PROGRAM_PENDING";
export const LEAVE_PROGRAM_REJECTED = "LEAVE_PROGRAM_REJECTED";
export const LEAVE_PROGRAM_FULFILLED = "LEAVE_PROGRAM_FULFILLED";

export const FETCH_TEMPLATES = "FETCH_TEMPLATES";
export const FETCH_TEMPLATES_PENDING = "FETCH_TEMPLATES_PENDING";
export const FETCH_TEMPLATES_REJECTED = "FETCH_TEMPLATES_REJECTED";
export const FETCH_TEMPLATES_FULFILLED = "FETCH_TEMPLATES_FULFILLED";

export const SAVE_TEMPLATE = "SAVE_TEMPLATE";
export const SAVE_TEMPLATE_PENDING = "SAVE_TEMPLATE_PENDING";
export const SAVE_TEMPLATE_REJECTED = "SAVE_TEMPLATE_REJECTED";
export const SAVE_TEMPLATE_FULFILLED = "SAVE_TEMPLATE_FULFILLED";

export const EXPORT_MULTIPLE_SHEETS = "EXPORT_MULTIPLE_SHEETS";
export const EXPORT_MULTIPLE_SHEETS_PENDING = "EXPORT_MULTIPLE_SHEETS_PENDING";
export const EXPORT_MULTIPLE_SHEETS_REJECTED = "EXPORT_MULTIPLE_SHEETS_REJECTED";
export const EXPORT_MULTIPLE_SHEETS_FULFILLED = "EXPORT_MULTIPLE_SHEETS_FULFILLED";

export const FETCH_EXPORT_KEYS = "FETCH_EXPORT_KEYS";
export const FETCH_EXPORT_KEYS_PENDING = "FETCH_EXPORT_KEYS_PENDING";
export const FETCH_EXPORT_KEYS_REJECTED = "FETCH_EXPORT_KEYS_REJECTED";
export const FETCH_EXPORT_KEYS_FULFILLED = "FETCH_EXPORT_KEYS_FULFILLED";
