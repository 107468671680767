import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchWorkspaces,
  exportMultipleSheets,
  fetchExportKeys,
} from "../../actions/workspaces.actions";
import { Button, FixedHeader, Input, Wallpaper } from "../../components";
import { Controller, useForm } from "react-hook-form";

function ExportAllPage() {
  const exportStatus = useSelector((state) => state.workspaces.exportStatus);
  const exportKeys = useSelector((state) => state.workspaces.exportKeys);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchExportKeys());
  }, []);

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      name: "",
    },
  });

  const formSubmit = handleSubmit((data) => {
    dispatch(exportMultipleSheets(data.name, data.email));
  });

  return (
    <Wallpaper className={"flex"}>
      <FixedHeader isWorkspaceHeader />
      <form
        onSubmit={formSubmit}
        className="mt-12 flex flex-col grow items-center justify-center "
      >
        <h1 className="text-2xl font-semibold mb-6">Export All Funnels</h1>

        <div className="space-y-6">
          <div className="input-component control">
            <label className="">List Name</label>

            <Controller
              control={control}
              name="name"
              rules={{
                required: "Please select a list to export",
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <div className="w-full">
                    <select
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      className="w-full p-2 border rounded-md appearance-none"
                      style={{
                        color: !field.value ? "#3636364d" : "#363636",
                      }}
                    >
                      <option value="" disabled>
                        Select a list to export
                      </option>
                      {exportKeys.map((key) => (
                        <option
                          key={key}
                          value={key}
                          className="text-[#363636]"
                        >
                          {key}
                        </option>
                      ))}
                    </select>
                    {error && (
                      <div className="text-red-500 text-sm mt-1">
                        {error.message}
                      </div>
                    )}
                  </div>
                );
              }}
            />
          </div>

          <Controller
            control={control}
            name="email"
            rules={{
              // required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            }}
            render={({ field, fieldState: { error } }) => {
              return (
                <div className="w-full">
                  <Input
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    inputId={"mail"}
                    label="Email (optional)"
                    inputType={"email"}
                    placeholder={"Enter your email"}
                  />
                  {error && (
                    <div className="text-red-500 text-sm mt-1">
                      {error.message}
                    </div>
                  )}
                </div>
              );
            }}
          />

          {exportStatus === "success" && (
            <div className="text-green-500 text-center">
              Export request sent successfully! Please check your email.
            </div>
          )}
          {exportStatus === "error" && (
            <div className="text-red-500 text-center">
              Failed to export sheets. Please try again.
            </div>
          )}
        </div>
        <Button
          type="submit"
          disabled={exportStatus === "pending"}
          className={"mt-4"}
        >
          {exportStatus === "pending" ? "Exporting..." : "Export Selected List"}
        </Button>
      </form>
    </Wallpaper>
  );
}

export default ExportAllPage;
