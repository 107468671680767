import * as types from "../types/workspaces.types";

const initialState = {
  isFetching: false,
  fetched: false,
  error: {},
  workspaces: [],
  templates: [],
  // TODO: Use workspace object instead
  programs: [],
  name: "",
  ownerId: "",
  programsCount: 0,
  exportStatus: null,
  exportKeys: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_WORKSPACES_PENDING: {
      return {
        ...state,
        isFetching: true,
        fetched: false,
        workspaces: [],
      };
    }
    case types.FETCH_WORKSPACES_FULFILLED: {
      const workspaces = [...action?.payload?.data?.workspaces];
      return {
        ...state,
        isFetching: false,
        fetched: true,
        workspaces,
      };
    }
    case types.FETCH_PROGRAMS_PENDING: {
      return {
        ...state,
        isFetching: true,
        fetched: false,
        programs: [],
        name: "",
        ownerId: "",
      };
    }
    case types.FETCH_PROGRAMS_FULFILLED: {
      const { workspace } = action.payload.data;
      return {
        ...state,
        isFetching: false,
        fetched: true,
        ...workspace,
      };
    }
    case types.FETCH_TEMPLATES_FULFILLED: {
      const templates = [...action.payload.data.templates];
      return {
        ...state,
        templates,
      };
    }
    case types.SAVE_PROGRAM_FULFILLED: {
      const { program } = action.payload.data;

      const myWorkspace = { ...state.workspaces[0] };
      const newProgramsArray = [...myWorkspace.programs].concat(program);
      const myNewWorkspace = { ...myWorkspace, programs: newProgramsArray };
      const newWorkspaces = [myNewWorkspace].concat(
        [...state.workspaces].slice(1)
      );

      return {
        ...state,
        workspaces: newWorkspaces,
      };
    }
    case types.EXPORT_MULTIPLE_SHEETS_PENDING: {
      return {
        ...state,
        exportStatus: "pending",
      };
    }
    case types.EXPORT_MULTIPLE_SHEETS_FULFILLED: {
      return {
        ...state,
        exportStatus: "success",
      };
    }
    case types.EXPORT_MULTIPLE_SHEETS_REJECTED: {
      return {
        ...state,
        exportStatus: "error",
        error: action.payload,
      };
    }

    case types.FETCH_EXPORT_KEYS_PENDING: {
      return {
        ...state,
        exportKeys: [],
      };
    }
    case types.FETCH_EXPORT_KEYS_FULFILLED: {
      return {
        ...state,
        exportKeys: action.payload.data.scriptKeys || [],
      };
    }
    case types.FETCH_EXPORT_KEYS_REJECTED: {
      return {
        ...state,
        exportKeys: [],
        error: action.payload,
      };
    }
    default:
      return state;
  }
}
